"use client"
import useSWR from "swr"
import { fetchApi } from "@local/utils/src/fetchApi"
import Cookies from "js-cookie"
import { useEffect, useMemo, useRef, useState } from "react"
import * as Sentry from "@sentry/nextjs"
import { isBrowser, useLocalStorage } from "@local/utils"
import type { IResult } from "@ua-parser-js/pro-enterprise"
import { WeatherApi } from "./weather/types"
import { getMainDomain } from "@local/utils/src/getMainDomain"
import { usePathname, useSearchParams } from "next/navigation"
import type { getGeo } from "@local/utils/src/getGeo"
import { IpApiResponse } from "./getIpApi"
import { useI10n } from "@local/i10n/src/useI10n"
import { useCart } from "@local/cart/src/useCart"
import { getCountryFromCurrency } from "@local/i10n/src/getCountryFromCurrency"

/* Base Call */
export interface RecoverResponse {
  bot?: boolean
  chills?: string | null
  ip: string | null
  isp?: string | null
  loc?: IpApiResponse
  geo?: Awaited<ReturnType<typeof getGeo>>
  proxy?: boolean | null
  ua?: IResult
  w?: WeatherApi.SimplifiedResponse | null
  vibes?: string | null
}

export const useIP = (channel?: string | null) => {
  const channelId = channel ?? (process.env.NEXT_PUBLIC_CHANNEL_ID as string)
  const [storage, setStorage] = useLocalStorage<string | null>("_r2", null)
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { init, getCountryFromCookie, currencyCode, country } = useI10n()
  const { currency: cartCurrency, reset } = useCart()
  const [_, setPhoneCountry] = useLocalStorage(
    "phoneCountry",
    // This is to avoid EU as country comming from our sources
    country && country.toUpperCase() !== "EU" ? country.toUpperCase() : "US"
  )

  const vibesStorage = useRef(searchParams.get("vb"))
  const chillsStorage = useRef(
    searchParams.get("ch") || Cookies.get("chills_s") || Cookies.get("chills")
  )
  const jugglerStorage = useRef(searchParams.get("pvd"))
  const hasRecovered = useRef(!!Cookies.get("_r2b"))

  const identifiers = useMemo(() => {
    if (isBrowser()) {
      const url = new URL(window.location.href)
      const params = url.searchParams

      const vibes = vibesStorage.current
      const chills = chillsStorage.current

      const domain = getMainDomain(process.env.NEXT_PUBLIC_APP_URL as string)

      const jugglerParam = params.get("ch")

      if (vibesStorage.current) window.$vb = vibesStorage.current
      if (chillsStorage.current) window.$ch = chillsStorage.current
      if (jugglerParam) window.$vvv = jugglerParam

      // Setup channel ID to be used by tracking scripts
      window.channel = channelId

      // More cookie setting fallback fallbackery
      if (!Cookies.get("chills") && !Cookies.get("chills_s") && chills) {
        const expires = new Date(Date.now() + 1000 * 60 * 60 * 1)
        Cookies.set("chills_s", chills, {
          secure: true,
          expires,
          domain,
        })
      }

      const recart: string | null = params.has("r_sub_id")
        ? params.get("r_sub_id")
        : null

      return {
        chills,
        recart,
        vibes,
      }
    } else {
      return null
    }
  }, [channelId])

  // Runs in parallel as this is part of migration for CDP
  const { data } = useSWR<RecoverResponse>(
    "ip",
    async () => {
      Sentry.setContext("identifiers", identifiers)
      Sentry.setUser({ ip_address: "{{auto}}" })

      return (await fetchApi({
        endpoint: "/api/r/",
        method: "POST",
        retries: 4,
        data: {
          c: chillsStorage.current ?? identifiers?.chills,
          l: window.location.href,
          r: document && document.referrer ? document.referrer : null,
          rc: identifiers?.recart,
          v: vibesStorage.current ?? identifiers?.vibes,
        },
      })) as RecoverResponse
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: true,
      refreshInterval: 0,
      // onErrorRetry: (_error, _key, _config, revalidate, { retryCount }) => {
      //   console.log("ERROR")
      //   if (retryCount >= 3) {
      //     Sentry.captureException("Error running recover")
      //   }
      //   revalidate({ retryCount })
      // },
      onSuccess: (res) => {
        setStorage(JSON.stringify(res))
        hasRecovered.current = true
        window.$r = res
        if (res.vibes) window.$vb = res.vibes
        if (res.chills) window.$ch = res.chills
        const ip = btoa(res.ip as string)
        Cookies.set("pie", ip, {
          secure: false,
        })
        if (res.geo?.country) {
          setPhoneCountry(res.geo?.country.toUpperCase())
        }
        Sentry.setContext("window_r", window.$r)
      },
    }
  )

  const consolidatedData = useMemo(() => {
    if (hasRecovered && hasRecovered.current) {
      try {
        const parsedLocal = JSON.parse(storage!) as RecoverResponse
        window.$r = parsedLocal ?? data
        return parsedLocal
      } catch (err) {
        if (data) {
          window.$r = data
        }
        return data ?? null
      }
    } else {
      return data ?? null
    }
  }, [data, hasRecovered, storage])

  // Save the IP on a cookie for session
  useEffect(() => {
    if (data) {
      window.$r = data
      if (!Cookies.get("pie") && data.ip) {
        const ip = btoa(data.ip)
        Cookies.set("pie", ip, {
          secure: false,
        })
      }
      setStorage(JSON.stringify(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hasRecovered])

  // This is used to regenerate some params and clear stuff from the URL
  useEffect(() => {
    let shouldUpdate = false
    if (isBrowser()) {
      const newParams = new URL(window.location.href)
      const vibesParam = searchParams.get("vb")
      const chillsParam = searchParams.get("ch")
      const jugglerParam = searchParams.get("pvd")
      const countryParam = searchParams.get("country")
      const currencyParam = searchParams.get("currency")

      // Initialise the country stuff
      let countryToSend: string | null = null
      let overrideCurrency = true

      if (currencyParam) {
        const currency = currencyParam?.toUpperCase() as string
        countryToSend = getCountryFromCurrency(currency)
      } else if (countryParam) {
        countryToSend = countryParam?.toLowerCase()
      } else {
        if (getCountryFromCookie()) {
          countryToSend = getCountryFromCookie() as string
        } else {
          overrideCurrency = false
          countryToSend = data?.geo?.country ?? "us"
        }
      }
      const { currency } = init(countryToSend, overrideCurrency)

      // This resets the cart if the currency on the cart ain't the same of the page
      if (currency && cartCurrency && cartCurrency !== currency) {
        reset()
      }

      if (vibesParam) {
        vibesStorage.current = searchParams.get("vb")
        window.$vb = vibesParam
        newParams.searchParams.delete("vb")
        shouldUpdate = true
      }
      if (chillsParam) {
        chillsStorage.current = chillsParam
        newParams.searchParams.delete("ch")
        window.$ch = chillsParam
        shouldUpdate = true
      }
      if (jugglerParam) {
        jugglerStorage.current = jugglerParam
        newParams.searchParams.delete("pvd")
        shouldUpdate = true
      }

      if (countryParam) {
        newParams.searchParams.delete("country")
        const cookieCountry = Cookies.get("country")
        if (!cookieCountry) {
          shouldUpdate = true
        }
      }

      if (currencyParam) {
        newParams.searchParams.delete("currency")
      }

      if (shouldUpdate) {
        // Replace the URL to get those items in place
        history.replaceState({}, "", newParams)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    // This resets the cart if the currency on the cart ain't the same of the page
    if (currencyCode && cartCurrency && cartCurrency !== currencyCode) {
      reset()
    }
  }, [cartCurrency, currencyCode, reset])

  const bot = consolidatedData?.bot
  const chills = consolidatedData?.chills
  const ip = consolidatedData?.ip
  const isp = consolidatedData?.isp
  const loc = consolidatedData?.loc
  const geo = consolidatedData?.geo
  const proxy = consolidatedData?.proxy
  const ua = consolidatedData?.ua
  const vibes = consolidatedData?.vibes
  const weather = consolidatedData?.w

  return {
    bot,
    channelId,
    chills,
    ip,
    isp,
    loc,
    geo,
    proxy,
    ua,
    vibes,
    weather,
  }
}
